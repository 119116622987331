import axios from 'axios'
import {
  getEmpleadorById,
  postEmpleadorByFilter,
  putActivarEmpleador,
  putDesactivarEmpleador,
  postEmpleadorSave,
  putEmpleadorUpdate,
  updateDatosGeneralesEmpleador,
  getVerificarCuit,
  getEmpleadorByRazonSocial,
  getEmpleadorByCuit,
} from '@/utils/paths'
// import { removeAccentsFromString } from '@/utils/stringUtils'

const pathGetEmpleadorById = getEmpleadorById
const pathPostEmpleadorByFilter = postEmpleadorByFilter
const pathPutActivarEmpleador = putActivarEmpleador
const pathPutDesactivarEmpleador = putDesactivarEmpleador
const pathPostEmpleadorSave = postEmpleadorSave
const pathPutEmpleadorUpdate = putEmpleadorUpdate
const pathGetVerificarCuit = getVerificarCuit
const pathUpdateDatosGenerales = updateDatosGeneralesEmpleador
const pathGetEmpleadorByRazonSocial = getEmpleadorByRazonSocial
const pathGetEmpleadorByCuit = getEmpleadorByCuit

export function empleadoresResource() {
  function findByIdEmpleador(idEmpleador) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetEmpleadorById + idEmpleador)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByRazonSocialEmpleador(razonSocial) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetEmpleadorByRazonSocial + razonSocial)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findEmpleadorByCuit(cuit) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetEmpleadorByCuit + cuit)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByEmpleadorByFilter(filterEmpleadorDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostEmpleadorByFilter, filterEmpleadorDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function activarEmpleador(idEmpleador) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutActivarEmpleador + idEmpleador)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function desactivarEmpleador(idEmpleador) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutDesactivarEmpleador + idEmpleador)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveEmpleador(empleadorDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostEmpleadorSave, empleadorDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateDatosGenerales(id, empleadorDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathUpdateDatosGenerales + id, empleadorDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateDatosContacto(id, empleadorDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutEmpleadorUpdate + id, empleadorDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByCuitEmpleador(cuit) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetVerificarCuit + cuit)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findByIdEmpleador,
    getByEmpleadorByFilter,
    activarEmpleador,
    desactivarEmpleador,
    saveEmpleador,
    updateDatosContacto,
    findByCuitEmpleador,
    updateDatosGenerales,
    findByRazonSocialEmpleador,
    findEmpleadorByCuit,
  }
}
