<template>
  <div>
    <validation-observer ref="formValidationConsultaEmpleador">
      <b-card>
        <b-row>
          <b-col md="12">
            <h4>Gestión de empleadores</h4>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col md="6">
            <b-form @submit.prevent="onSubmit">
              <b-form-group
                id="frmSerchCuitRazonSocial"
                label="C.U.I.T./Razón Social/Nombre de Fantasía"
              >
                <b-input-group>
                  <b-input-group-append>
                    <b-button
                      v-if="hasContent"
                      id="btnClearSearchEmpleador"
                      v-b-tooltip.hover="'Limpiar filtro'"
                      class="closeStyle"
                      size="sm"
                      @click="()=>clearSearchEmpleador()"
                    >
                      <font-awesome-icon icon="fa-solid fa-eraser" />
                    </b-button>
                  </b-input-group-append>
                  <b-form-input
                    id="txtFiltro"
                    v-model="filtro"
                    :disabled="isBusyBuscando"
                    autocomplete="off"
                    placeholder=" Búsqueda por ( C.U.I.T. - Razón Social/Nombre de Fantasía )"
                    maxlength="250"
                  />
                </b-input-group>
              </b-form-group></b-form>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmProvincia"
              label="Provincia"
            >
              <b-form-select
                id="selectProvincia"
                v-model="empleadorDto.provinciaId"
                v-b-tooltip.hover="'Seleccione la provincia'"
                :options="optionsProvincia"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoProvincias || isBusyBuscando"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmEstado"
              label="Estado"
            >
              <b-form-select
                id="selectEstado"
                v-model="empleadorDto.estadoId"
                v-b-tooltip.hover="'Seleccione el estado'"
                :options="optionsEstado"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscando"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <div class="mycontainer">
              <b-button
                id="btnClearSearch"
                v-b-tooltip.hover="'Limpiar filtros'"
                squared
                size="sm"
                class="closeStyle"
                :disabled="isBusyBuscando"
                @click="()=>clearSearch()"
              >

                <font-awesome-icon icon="fa-solid fa-eraser" />
              </b-button>
              <b-button
                id="btnSearchBeneficio"
                v-b-tooltip.hover
                title="Buscar Empleador"
                variant="primary"
                squared
                size="sm"
                :disabled="isBusyBuscando"
                @click="buscarEmpleador()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnAddEmpleador"
                v-b-tooltip.hover
                squared
                size="sm"
                title="Agregar nuevo Empleador"
                variant="success"
                @click="()=>addEmpleador()"
              >
                <feather-icon icon="PlusIcon" />

              </b-button>
            </div>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col md="12">
            <b-table
              id="tblEmpleador"
              :items="tableDataEmpleador"
              :busy="isBusyBuscando"
              :fields="fieldsEmpleador"
              outlined
              show-empty
              small
              hover
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No se encontraron resultados para esta búsqueda"
              @row-dblclicked="dobleClik"
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(estado)="row">
                <small>
                  {{ row.item.estado }}
                </small>

              </template>
              <template #cell(actions)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <b-button
                    v-if="permisosVer.includes(nameModulo) && row.item.cargaCompletada"
                    v-b-tooltip.hover.righttop="'Ver Empleador'"
                    size="sm"
                    @click="viewEmpleador(row.item, row.index, $event.target)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="permisosUpd.includes(nameModulo) && row.item.cargaCompletada"
                    v-b-tooltip.hover.righttop="'Editar Empleador'"
                    size="sm"
                    @click="editEmpleador(row.item, row.index, $event.target)"
                  >
                    <!--<feather-icon icon="EyeIcon" />-->
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                  </b-button>
                  <b-button
                    v-if="permisosIns.includes(nameModulo) && !row.item.cargaCompletada"
                    v-b-tooltip.hover.righttop="'Completar Carga Empleador'"
                    size="sm"
                    @click="completeUpload(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-solid fa-user-pen" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-input-group>

                <b-input-group-append>
                  <b-form-select
                    v-model="pageEmpleador.size"
                    :clearable="false"
                    label="title"
                    size="sm"
                    :options="optionsPaginationSize"
                    :disabled="isBusyBuscando"
                    title="Seleccione la cantidad de resultados por página"
                    @change="buscarEmpleador()"
                  />
                </b-input-group-append>

                <b-pagination
                  id="pgnEmpleador"
                  v-model="currentPage"
                  :total-rows="pageTotalElements"
                  :per-page="pageSize"
                  :disabled="isBusyBuscando"
                  @change="changePaginate"
                />
              </b-input-group>
            </b-col>
          </div>
        </b-row>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { empleadoresResource } from '@/services/empleadoresResource'
import { provinciaResource } from '@/services/provinciaResource'
import { empleadorEstadoResource } from '@/services/empleadorEstadoResource'

export default {
  components: {
    // BCardCode,
  },
  data() {
    return {
      useLocalStorageLastSearch: true,
      cargando: false,
      isBusyBuscandoEstados: false,
      isBusyBuscandoProvincias: false,
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      pageEmpleador: {
        page: 0,
        size: 7,
      },
      nameModulo: 'ABM_EMPLEADORES',
      isBusyBuscando: false,
      isActivando: false,
      empleadorDto: {
        razonSocial: null,
        nombreFantasia: null,
        provinciaId: null,
        estadoId: null,
        fechaCarga: null,
        activo: null,
      },
      rolGestor: false,
      rolConsultor: false,
      rolConsultorDelegacion: false,
      userData: JSON.parse(localStorage.getItem('ls.CURRENT-USER')),
      optionsEstado: [
        { id: null, nombre: 'Todos' },
      ],
      optionsProvincia: [
        { id: null, nombre: 'Todos' },
      ],
      isDesactivando: false,
      tableDataEmpleador: [],
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      fieldsEmpleador: [
        {
          key: 'cuit', label: 'C.U.I.T.', thStyle: { width: '20%' },
        },
        {
          key: 'razonSocial', label: 'Razón Social', thStyle: { width: '25%' },
        },
        {
          key: 'nombreFantasia', label: 'Nombre de Fantasía', thStyle: { width: '25%' },
        },
        {
          key: 'empleadorEstado.nombre', label: 'Estado', thStyle: { width: '20%' },
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
    }
  },

  computed: {
    hasContent() {
      return (this.filtro != null && this.filtro.length > 0)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  created() {
    this.getEmpleadorEstadoLista()
    this.getProvinciaLista()
    this.buscarEmpleador()
  },
  methods: {
    dobleClik(record) {
      if (record.cargaCompletada === null || record.cargaCompletada === false) {
        this.$router.push(`/empleador/incompleteLoad/${record.id}`)
      } else {
        // La carga está completada, abrimos el detalle. y NO el wizard
        this.$router.push(`/empleador/view/${record.id}`)
      }
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.buscarEmpleador()
      }
    },
    clearSearchEmpleador() {
      this.filtro = ''
    },
    clearSearch() {
      this.filtro = ''
      this.empleadorDto.estadoId = null
      this.empleadorDto.provinciaId = null
    },
    viewEmpleador(item) {
      this.$router.push(`/empleador/view/${item.id}`)
    },
    editEmpleador(item) {
      this.$router.push(`/empleador/edit/${item.id}`)
    },
    completeUpload(item) {
      this.$router.push(`/empleador/incompleteLoad/${item.id}`)
    },
    addEmpleador() {
      this.$router.push('/empleador/new')
    },
    buscarEmpleador() {
      this.currentPage = 1
      this.pageEmpleador.page = 0
      this.getEmpleadorByFilter()
    },
    getEmpleadorByFilter() {
      this.tableDataEmpleador = []
      this.isBusyBuscando = true
      this.getBuscarRoles()
      const filtros = {
        filtro: this.filtro,
        provinciaId: this.empleadorDto.provinciaId,
        estadoId: this.empleadorDto.estadoId,
        rolConsultorDelegacion: this.rolConsultorDelegacion,
        rolConsultor: this.rolConsultor,
        rolGestor: this.rolGestor,
        delegacionId: this.userData.delegacion.id,
        pageNumber: this.pageEmpleador.page,
        pageSize: this.pageEmpleador.size,
        sort: 'razonSocial',
        order: 'asc',
      }
      empleadoresResource().getByEmpleadorByFilter(filtros)
        .then(resultGetByEmpleadorByFilter => {
          this.tableDataEmpleador = resultGetByEmpleadorByFilter.content
          this.pageTotalElements = resultGetByEmpleadorByFilter.totalElements
          this.pageNumber = resultGetByEmpleadorByFilter.number
          this.pageSize = resultGetByEmpleadorByFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataEmpleador = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    getBuscarRoles() {
      this.userData.roles.forEach(rol => {
        if ('GESTOR_EMPLEADORES'.includes(rol.codigo)) {
          this.rolGestor = true
        } else if ('CONSULTOR_EMPLEADORES'.includes(rol.codigo)) {
          this.rolConsultor = true
        } else if ('CONSULTOR_EMPLEADORES_DELEGACION'.includes(rol.codigo)) {
          this.rolConsultorDelegacion = true
        }
      })
    },
    getEmpleadorEstadoLista() {
      this.isBusyBuscandoEstados = true
      empleadorEstadoResource().getAllEmpleadorEstado().then(result => {
        this.optionsEstado = this.optionsEstado.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstados = false
        })
    },
    getProvinciaLista() {
      this.isBusyBuscandoProvincias = true
      provinciaResource().getAllProvincia().then(result => {
        this.optionsProvincia = this.optionsProvincia.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoProvincias = false
        })
    },
    getByEmpleadorBySpecs(filtros) {
      this.isBusyBuscando = true
      empleadoresResource().getByEmpleadorBySpecs(filtros)
        .then(resultGetByEmpleadorBySpecs => {
          this.tableDataEmpleador = resultGetByEmpleadorBySpecs.content
          this.pageTotalElements = resultGetByEmpleadorBySpecs.totalElements
          this.pageNumber = resultGetByEmpleadorBySpecs.number
          this.pageSize = resultGetByEmpleadorBySpecs.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataEmpleador = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    desactivarEmpleador(item) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea desactivar el Empleador ${item.nombreFantasia}?</p>`
      this.$swal({
        title: 'Desactivación',
        // text: `¿Desea desactivar el Empleador ${item.nombreFantasia}?`,
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.tableDataEmpleador.find(empleador => empleador.id === item.id).isProcesando = true
          empleadoresResource().desactivarEmpleador(item.id)
            .then(resultsDeleteDeleteEmpleador => {
              // this.getEmpleadorByFilter(this.filterEmpleador, this.pageEmpleador)
              this.tableDataEmpleador.find(empleador => empleador.id === resultsDeleteDeleteEmpleador).estado = 'Inactivo'
            }).catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.tableDataEmpleador.find(empleador => empleador.id === item.id).isProcesando = false
            })
        }
      })
    },
    activarEmpleador(item) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea reactivar el Empleador ${item.nombreFantasia}?</p>`
      this.$swal({
        title: 'Reactivación',
        // text: `¿Desea reactivar el Empleador ${item.nombreFantasia}?`,
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.tableDataEmpleador.find(empleador => empleador.id === item.id).isProcesando = true
          empleadoresResource().activarEmpleador(item.id)
            .then(resultsReactivarEmpleador => {
              this.tableDataEmpleador.find(empleador => empleador.id === resultsReactivarEmpleador).estado = 'Activo'
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.tableDataEmpleador.find(empleador => empleador.id === item.id).isProcesando = false
            })
        }
      })
    },
    changePaginate(item) {
      this.pageEmpleador.page = item - 1
      this.isBusyBuscando = true
      this.getEmpleadorByFilter()
    },
  },
}
</script>
<style>
.closeStyle {
  border-color: #cad1d7;
}
.text-red {
  color: red;
}
.mycontainer {
  text-align: center;
  padding-top: 28px;
}
</style>
